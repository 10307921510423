import BaseBean from "@/utils/BaseBean";

export interface IBusinessPiListDataObj {
    utilInst:BusinessPiListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class BusinessPiListUtil extends BaseBean{
    public dataObj:IBusinessPiListDataObj

    constructor(proxy:any,dataObj:IBusinessPiListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}